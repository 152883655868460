<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="name"
            hide-details="auto"
            :disabled="isLoading"
            :rules="[rules.required, rules.maxChars(255)]"
            :label="$trans('simple_store_product_list_product_name')"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="price"
            :disabled="isLoading"
            hide-details="auto"
            type="number"
            :rules="[rules.required, rules.maxNumber]"
            :label="$trans('simple_store_product_list_product_price')"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("invoice_info_1") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-checkbox
            v-model="wantsInvoice"
            :disabled="isLoading"
            hide-details
            :label="$trans('wants_invoice_select_title')"
          />
        </v-col>
      </v-row>
      <v-row v-if="wantsInvoice">
        <v-col class="d-flex align-center">
          {{ $trans("tax_amount_title") }}

          <v-select
            v-model="tax"
            :disabled="isLoading"
            dense
            style="max-width: 120px"
            class="ml-2"
            outlined
            hide-details="auto"
            :title="$trans('select')"
            :rules="[rules.required]"
            append-icon="$percent"
            :items="taxValues"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <tip-tap
            v-model="additionalInformation"
            :label="$trans('simple_store_product_additional_information_title')"
            :placeholder="
              $trans('simple_store_product_additional_information_title')
            "
            :subtitle="
              $trans('simple_store_product_additional_information_description')
            "
            :disabled="isLoading"
            @input="additionalInformation = $event"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <tip-tap
            v-model="description"
            :label="$trans('simple_store_product_description_title')"
            :placeholder="$trans('simple_store_product_description_title')"
            :subtitle="$trans('simple_store_product_description_info')"
            :disabled="isLoading"
            @input="description = $event"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="font-weight-medium">
          {{ $trans("wb_gdpr_fields") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <gdpr-fields v-model="gdprFields" @change="gdprFields = $event" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="successUrl"
            hide-details="auto"
            :disabled="isLoading"
            :rules="successUrl ? [rules.maxChars(255), rules.url] : []"
            :label="$trans('subscription_success_url')"
            :hint="$trans('subscription_success_url_example')"
            persistent-hint
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="cancelUrl"
            hide-details="auto"
            :disabled="isLoading"
            :rules="cancelUrl ? [rules.maxChars(255), rules.url] : []"
            :label="$trans('subscription_cancel_url')"
            :hint="$trans('subscription_cancel_url_example')"
            persistent-hint
            outlined
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-checkbox
            v-model="status"
            :disabled="isLoading"
            hide-details
            false-value="inactive"
            true-value="active"
            :label="$trans('product_active')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxChars,
  maxNumber,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  getTaxValues,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import TipTap from "@/lib/calendesk-js-library/components/TipTap";
import GdprFields from "@/components/GdprFields/GdprFields";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "AddUpdateSimpleStoreProductDialog",
  components: { GdprFields, TipTap, CalendeskInformationMessage },
  mixins: [sharedActions],
  data() {
    return {
      taxValues: getTaxValues(),
      isLoading: false,
      id: null,
      name: null,
      description: null,
      additionalInformation: null,
      gdprFields: null,
      price: null,
      wantsInvoice: false,
      successUrl: null,
      cancelUrl: null,
      status: "active",
      tax: "23",
      rules: {
        required,
        maxChars,
        maxNumber,
        url,
      },
    };
  },
  created() {
    if (this.getDialog.data && this.getDialog.data.product) {
      this.id = this.getDialog.data.product.id;
      this.name = this.getDialog.data.product.name;
      this.description = this.getDialog.data.product.description;
      this.price = this.getDialog.data.product.price / 100;
      this.wantsInvoice = this.getDialog.data.product.wants_invoice;
      this.tax = this.getDialog.data.product.tax;
      this.successUrl = this.getDialog.data.product.success_url;
      this.cancelUrl = this.getDialog.data.product.cancel_url;
      this.status = this.getDialog.data.product.status;
      this.additionalInformation =
        this.getDialog.data.product.additional_information;
      this.gdprFields = this.getDialog.data.product.gdpr_fields;
    }
  },
  methods: {
    ...mapActions({
      refreshProductList: "simpleStore/refreshProductList",
      fetchStats: "dashboard/fetchStats",
      create: "simpleStore/create",
      updateProduct: "simpleStore/update",
    }),
    handleSave() {
      if (this.$refs.form.validate()) {
        if (this.id) {
          this.update();
        } else {
          this.add();
        }
      }
    },
    async add() {
      this.isLoading = true;
      this.$emit("ctaButtonLoading", true);
      try {
        pushEvent("createSimpleStoreProduct");

        await this.create({
          name: this.name,
          description: this.description,
          price: Math.round(this.price * 100),
          tax: this.wantsInvoice ? this.tax : null,
          wants_invoice: this.wantsInvoice,
          success_url: this.successUrl,
          cancel_url: this.cancelUrl,
          status: this.status,
          additional_information: this.additionalInformation,
          gdpr_fields: JSON.stringify(this.gdprFields),
        });

        await this.fetchStats();
        await this.refreshProductList();
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("ctaButtonLoading", false);
        this.$emit("close");
      }
    },
    async update() {
      this.isLoading = true;
      this.$emit("ctaButtonLoading", true);
      try {
        pushEvent("updateSimpleStoreProduct");

        await this.updateProduct({
          id: this.id,
          name: this.name,
          description: this.description,
          price: Math.round(this.price * 100),
          tax: this.wantsInvoice ? this.tax : null,
          wants_invoice: this.wantsInvoice,
          success_url: this.successUrl,
          cancel_url: this.cancelUrl,
          status: this.status,
          additional_information: this.additionalInformation,
          gdpr_fields: JSON.stringify(this.gdprFields),
        });

        await this.refreshProductList();
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("ctaButtonLoading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
